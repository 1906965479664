//    ###   ##     ####   ####   #####  ####         
//   ## ##  ##      ##    ## ##  ##     ## ##        
//   ##     ##      ##    ## ##  ##     ## ##        
//    ###   ##      ##    ## ##  ####   ####         
//      ##  ##      ##    ## ##  ##     ###          
//   ## ##  ##      ##    ## ##  ##     ####         
//    ###   #####  ####   ####   #####  ## ##        
//                                                   
//                                                   


$(document).ready(function(){

    var slider = new Swiper('.slider', {
        slideClass: 'slider__item',
        slidesPerView: '1',
        effect: 'fade',
        speed: 1900,
        autoplay: 2000,
        loop: true,
        touchMoveStopPropagation: false,
        fade: {
            crossFade: true
        }
    });

    

});

//
//    ####    ###   ####          ## ##  ####
//    ## ##  ## ##  ## ##         ## ##  ## ##
//    ## ##  ## ##  ## ##         ## ##  ## ##
//    ####   ## ##  ####   #####  ## ##  ####
//    ##     ## ##  ##            ## ##  ##
//    ##     ## ##  ##            ## ##  ##
//    ##      ###   ##             ###   ##
//
//

$(document).ready(function(){
    $('.galeria').Chocolat({
    	imageSelector: '.popup-image',
    	linkImages: 'false'

    });

});


// var $grid = $('.galeria').isotope({
//   itemSelector: '.galeria__item',
//   layoutMode: 'fitRows'
// });

//
//    #   #    #     ###    ###   #  ##  ####   ## ##
//    ## ##   ###   ## ##  ## ##  ## ##  ## ##  ## ##
//    #####  ## ##  ##     ## ##  #####  ## ##  ## ##
//    #####  ## ##   ###   ## ##  #####  ####    ###
//    #####  #####     ##  ## ##  #####  ###     ###
//    ## ##  ## ##  ## ##  ## ##  ## ##  ####    ###
//    ## ##  ## ##   ###    ###   ##  #  ## ##   ###
//
//

var $grid = $('.galeria').masonry({
  itemSelector: '.galeria__item',
  columnWidth: '.galeria__item',
  isFitWidth: true,
  transitionDuration: 0,
  percentPosition: true,
  initLayout: false
});

$grid.masonry();

// document.addEventListener('lazybeforeunveil', function(e){
    
//         $grid.masonry();
   
// });

// $(document).on('lazybeforesizes', function(e){
//     $grid.masonry();
// });

//
//    ####    ###    ###  ######   ###   ####   #####
//     ##    ## ##  ## ##   ##    ## ##  ## ##  ##
//     ##    ##     ## ##   ##    ## ##  ## ##  ##
//     ##     ###   ## ##   ##    ## ##  ####   ####
//     ##       ##  ## ##   ##    ## ##  ##     ##
//     ##    ## ##  ## ##   ##    ## ##  ##     ##
//    ####    ###    ###    ##     ###   ##     #####
//
//

// function getHashFilter() {
//   // get filter=filterName
//   var matches = location.hash.match( /filter=([^&]+)/i );
//   var hashFilter = matches && matches[1];
//   return hashFilter && decodeURIComponent( hashFilter );
// }

// // init Isotope
// var $grid = $('.galeria');

// // bind filter button click
// var $filterButtonGroup = $('.galeria__filter-group');
// $filterButtonGroup.on( 'click', '.galeria__filter-item', function() {
//   var filterAttr = $( this ).attr('data-filter');
//   // set filter in hash
//   location.hash = 'filter=' + encodeURIComponent( filterAttr );
// });

// var isIsotopeInit = false;

// function onHashchange() {
//   var hashFilter = getHashFilter();
//   if ( !hashFilter && isIsotopeInit ) {
//     return;
//   }
//   isIsotopeInit = true;
//   // filter isotope
//   $grid.isotope({
//     itemSelector: '.galeria__item',
//     // layoutMode: 'fitRows',
//       percentPosition: true,
//     // use filterFns
//     filter: hashFilter,
//     masonry: {
//         // use element for option
//         columnWidth: '.galeria__sizer'
//     }
//   });
//   // set selected class on button
//   if ( hashFilter ) {
//     $filterButtonGroup.find('.is-checked').removeClass('is-checked');
//     $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
//   }
// }

// $(window).on( 'hashchange', onHashchange );

// trigger event handler to init Isotope
  // onHashchange();

// layout Isotope after each image loads
// $grid.imagesLoaded().progress( function() {
//   $grid.isotope('layout');
// });


//                              
//   #   #  #####  #  ##  ## ## 
//   ## ##  ##     ## ##  ## ## 
//   #####  ##     #####  ## ## 
//   #####  ####   #####  ## ## 
//   #####  ##     #####  ## ## 
//   ## ##  ##     ## ##  ## ## 
//   ## ##  #####  ##  #   ###  
//                              
//                              


// $(document).ready(function() {

//   $('#menu-toggle').click(function (e) {
//       $('#menu').toggleClass('nav__menu--is-open');
//       e.preventDefault();
//     });
    

//   $(window).bind('scroll', function () {
      
//       if ($(window).scrollTop() > 120)
//           $('.top-header').addClass('nav-down');
//       else
//           $('.top-header').removeClass('nav-down');
//   });


//   // Hide Header on on scroll down
//   var lastScrollTop = 0;
//   var delta = 110;
//   var navbarHeight = $('.top-header').outerHeight();

//   $(window).scroll(function(event){
     
//       var st = $(this).scrollTop();
//       // console.log(st);
//       // Make sure they scroll more than delta
//       if(Math.abs(lastScrollTop - st) <= delta)
//           return;
      
//       // If they scrolled down and are past the navbar, add class .nav-up.
//       // This is necessary so you never see what is "behind" the navbar.
//       if (st > lastScrollTop && st > navbarHeight){
//           // Scroll Down
//           $('.top-header').removeClass('nav-down').addClass('nav-up');
//       } else {
//           // Scroll Up
//           if(st + $(window).height() < $(document).height()) {
//               $('.top-header').removeClass('nav-up');
//           }
//       }
      
//       lastScrollTop = st;
//   });

// });


//                                                   
//   ## ##  #####  ##     ####   #####  ####    ###  
//   ## ##  ##     ##     ## ##  ##     ## ##  ## ## 
//   ## ##  ##     ##     ## ##  ##     ## ##  ##    
//   #####  ####   ##     ####   ####   ####    ###  
//   ## ##  ##     ##     ##     ##     ###       ## 
//   ## ##  ##     ##     ##     ##     ####   ## ## 
//   ## ##  #####  #####  ##     #####  ## ##   ###  
//                                                   
//            


jQuery(document).ready(function($) {

    //Add box shadow border to divs                                       
    $('body.dev *').each(function() {
        var colors = ['red', 'blue', 'yellow', 'black', 'green'];
        var random_color = colors[Math.floor(Math.random() * colors.length)];
        var box_shadow = '0 0 2px ' + random_color + ' inset';
        
        if ($(this).css('box-shadow') === 'none') {
            $(this).css('box-shadow', box_shadow);
        }
    });



    // adds ios class to html tag

    var deviceAgent = navigator.userAgent.toLowerCase();
    var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
    if (agentID) {

        $('html').addClass('ios');

    }

    //adds touch-screen to html if device
    if (Modernizr.touch) {
        $('html').addClass('touch-screen');
    } else {
        $('html').addClass('no-touch-screen');
    }

}); //end ready

